.absence-event {
  border-radius: 3px;
  margin-bottom: 10px;
  width: 100%;
}
.fc-event-title {
  overflow: hidden;
  text-overflow: ellipsis;
}
.fc-timeline-bg-harness {
  cursor: crosshair;
}
.fc-day-today {
  background: rgba(55, 109, 244, 0.1) !important;
}
.fc-day-sat {
  background: rgb(233, 233, 233, 0.5) !important;
}
.fc-day-sun {
  background: rgb(233, 233, 233, 0.5) !important;
}

.hovertext {
  position: relative;
  border-bottom: 1px dotted black;
}

.hovertext:before {
  content: attr(data-hover);
  visibility: hidden;
  opacity: 0;
  width: 140px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px 0;
  transition: opacity 1s ease-in-out;
  position: absolute;
  z-index: 1;
  left: 0;
  top: 110%;
}

.hovertext:hover:before {
  opacity: 1;
  visibility: visible;
}
