/* full calendar style overrides */
/*
.fc {
  height: 100%;
  background-color: white;
}
*/

.fc .fc-scrollgrid-liquid {
  border-radius: 5px;
}

.fc .fc-resource-timeline-divider {
  width: 1px !important;
}
/*
on each calendar which there are events which are not background and do not have any
other specific css class, set this as event class to render the event width correctly.
*/
.generic-event {
  width: 100% !important;
}

.fc-daygrid-day-events {
  overflow: clip;
}

.fc-event {
  width: auto;
}

/* workaround for squeezed vertical view upon opening timeline calendars. */
.fc-datagrid-body tbody tr {
  height: 128px;
}
.fc-timeline-lane {
  height: 128px;
}
/* end of squeezed vertical view workaround */

/**
 * workaround for broken multi-day events. They would only displayed on
 * some days and are very confusing to understand why. At first it looks
 * as if it is because of some data or programming error, but it's style.
 **/
.fc .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
  position: unset !important;
}
.fc-daygrid-event-harness {
}

.fc-daygrid-day-number {
  font-size: 18px;
  font-weight: bold;
}
/* end of broken multi-day workaround */

/**
 * Let the calendar fill its parent container by default. Note that we
 * have a ratio function that would calculate the space around events,
 * in its container, which has nothing to do with this one.
 **/
.fc {
  height: 100% !important;
}
