.event-booked {
  background-color: #fc424288 !important;
  border: 2px #fc4242 dashed !important;
  border-radius: 5px !important;
  margin-top: 4px !important;
  width: 100% !important;
}
.event-selected {
  background-color: #376df488 !important;
  border: 2px #376df4 dashed !important;
  border-radius: 5px !important;
  margin-top: 4px !important;
  width: 100% !important;
}
.event-suggested {
  background-color: #d5f5d055 !important;
  border: 2px #95e68b dashed !important;
  border-radius: 5px !important;
  margin-top: 4px !important;
  width: 100% !important;
}

.change-event-booked {
  background-color: #376df488 !important;
  border: 0 !important;
  border-radius: 2px !important;
  margin-top: 2px !important;
  width: 100% !important;
}
.change-event-booked .fc-event-title {
  font-size: 12px !important;
}
.change-event-booked .fc-event-time {
  display: none !important;
}
.change-event-current {
  background-color: #fc424288 !important;
  border: 0 !important;
  border-radius: 2px !important;
  margin-top: 2px !important;
  width: 100% !important;
}
.change-event-current .fc-event-title {
  font-size: 12px !important;
}
.change-event-current .fc-event-time {
  display: none !important;
}
.change-event-main {
  background-color: #2dce18 !important;
  border: 0 !important;
  border-radius: 2px !important;
  margin-top: 2px !important;
  width: 100% !important;
}
.change-event-main .fc-event-title {
  font-size: 12px !important;
}
.change-event-main .fc-event-time {
  display: none !important;
}

.change-event-maintenance {
  background-color: #f49537 !important;
  border: 0 !important;
  border-radius: 2px !important;
  margin-top: 2px !important;
  width: 100% !important;
}
.change-event-maintenance .fc-event-title {
  font-size: 12px !important;
}
.change-event-maintenance .fc-event-time {
  display: none !important;
}

.fc-event-title {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.fc-day-today {
  background: rgba(55, 109, 244, 0.1) !important;
}
.fc-event-title:hover {
  overflow: visible !important;
  text-overflow: initial !important;
}

.fc .fc-datagrid-cell-cushion {
  padding: 0 !important;
}

.fc-datagrid-cell-frame {
  cursor: pointer !important;
}
