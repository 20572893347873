@import 'fonts.css';

/* add tailwindcss directives */
@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Red Hat Display', 'Verdana', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Border-box is a better boxing model and we are already setting
     it in numerous places all over our code-base. For more info see:
     https://developer.mozilla.org/en-US/docs/Web/CSS/box-sizing */
  box-sizing: border-box;
}

#root {
  /* Opacity is set to 1 in a onetime effect inside app.js */
  opacity: 0;
  transition: opacity 0.5s;
  display: flex;
  height: 100vh;
}

code {
  font-family: 'Courier New', monospace;
}

input {
  font-size: 16px;
  letter-spacing: 0.32px;
  color: #303030;
}

textarea {
  font-size: 16px;
  letter-spacing: 0.32px;
  color: #303030;
  width: 100%;
  white-space: pre-wrap;
  font-family: monospace;
}

p {
  hyphens: auto;
  display: inline-block;
  font-size: 16px;
  letter-spacing: 0.32px;
  color: #303030;
  margin: 0;
}

pre {
  font-size: 16px;
  letter-spacing: 0.32px;
  color: #303030;
}

a {
  display: inline-block;
  font-size: 16px;
  letter-spacing: 0.32px;
  color: #303030;
  text-decoration: underline;
  text-decoration-style: dotted;
  text-decoration-color: #bbb;
  text-decoration-thickness: 0.5px;
  width: fit-content;
}

a:hover {
  text-decoration-color: #888;
  text-decoration-thickness: 0.5px;
}

h1 {
  display: inline-block;
  font-size: 22px;
  letter-spacing: 0.44px;
  color: #303030;
  margin: 0;
}

h2 {
  display: inline-block;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.4px;
  color: #303030;
  margin: 0;
}

h3 {
  display: inline-block;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0.32px;
  color: #303030;
  margin: 0;
}

h4 {
  display: inline-block;
  font-size: 14px;
  letter-spacing: 0.32px;
  color: #303030;
  margin: 0;

  /* Word-wrapping for long mainly German words */
  overflow-wrap: anywhere;
  hyphens: auto;
  /* text-overflow: ellipsis; also possible */
  /* Word-wrapping end */
}

h5 {
  display: inline-block;
  font-size: 12px;
  letter-spacing: 0.24px;
  color: #808080;
  margin: 0;
}

label {
  font-size: 12px;
  color: #808080;
  display: flex;
  flex-direction: column;
}

fieldset {
  align-self: center;
  padding: 10px;
  margin: 0;
  width: 100%;
  border: 1px #d7d7d7 solid;
  display: flex;
  gap: 10px;
}

fieldset label input {
  width: 100% !important;
}

legend {
  font-family: Red Hat Display !important;
  color: #303030;
}

@media print {
  button {
    display: none !important;
  }
}

.error {
  color: #fc4242;
}
